<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'purchaseSlip'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content border0">
          <div class="modal-header">
            <div class="row">
              <div class="col-md-6">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                  Purchase Slip - #{{ details.id }}
                </h5>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-print" @click="print"></i>
                </div>
                <div class="bill-head-icon mr-3">
                  <i class="fas fa-download"></i>
                </div>
                <div
                  class="bill-head-icon mr-1"
                  @click="$store.dispatch('modalClose', 'purchaseSlip')"
                >
                  <i class="fas fa-times"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="p-2 bill-container" id="printMe">
            <div class="text-center" v-if="dataLists9.organization">
              <p class="font-xxl mb-0">{{ dataLists9.organization.name }}</p>
              <p class="mb-0">
                {{ details.branch.address }}
                <br />Tel No: {{ details.branch.phone }} / {{ details.branch.email }}
                <br />PAN: {{ dataLists9.organization.pan_vat }}
              </p>
              <hr />
            </div>
            <!-- col -->
            <div class="row">
              <div class="col-sm-6 col-lg-8 mg-t-40 mg-sm-t-0" v-if="details.supplier">
                <label class="content-label mb-1">Purchased from</label>
                <!--    <h6 class="tx-15 mg-b-10">Juan Dela Cruz</h6> -->
                <p class="mg-b-0">NAME : {{ details.supplier.full_name }}</p>
                <p class="mg-b-0">ADDRESS : {{ details.supplier.address }}</p>
                <p class="mg-b-0">PAN : {{ details.supplier.pan_vat_no }}</p>
              </div>
              <!-- col -->
              <div class="col-sm-6 col-lg-4 mg-t-15">
                <p class="mg-b-0 text-right">
                  Transaction ID : #PRCS{{ details.id }}
                </p>
                <p class="mg-b-0 text-right">
                  DATE : {{ details.bill_date }}
                </p>
                <p class="mg-b-0 text-right" v-if="details.chalan_no">
                  Chalan No : {{ details.chalan_no }}
                </p>
              </div>
              <!-- col -->
            </div>
            <!-- row -->
            <div class="mt-2">
              <table class="table table3">
                <thead class="bg-none">
                  <tr class="bg-none">
                    <th scope class="wd-5p">S.N</th>
                    <th scope class="wd-60p">Particular</th>
                    <th scope class="wd-15p">Qty</th>
                    <th scope class="wd-10p">Rate</th>
                    <th scope class="wd-10p tx-right">Total</th>
                  </tr>
                </thead>
                <tbody class="hover-none">
                  <tr v-for="(item, index) in details.items" :key="index">
                    <th scope="row">{{ ++index }}</th>
                    <td>
                      {{ item.item.name }}
                    </td>
                    <td>{{ item.quantity }} {{ item.item.unit_type.title }}</td>
                    <td>{{ item.purchase_rate }}</td>
                    <td class="tx-right">{{ item.total }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row justify-content-between mt-2 font-s font-w-400">
              <div
                class="col-sm-7 col-lg-7 order-2 order-sm-0 mg-t-40 mg-sm-t-0"
              >
                <!-- Amount-in-word -->
                <div class="footer_detail mt-0 mb-2 p-2">
                  <label class="content-label mb-1">Amount In Word</label>
                  <p class="mg-b-0">
                    {{ toWord(details.bill_amount) }}
                  </p>
                </div>
                <div class="table-responsive" v-if="details.paid_amount > 0">
                  <table
                    class="table table-bordered mg-b-0 table_lr_border color-secondary"
                  >
                    <thead>
                      <tr>
                        <th scope="col" class="border-right_1">Payment Type</th>
                        <th scope="col" class="border-right_1">Payment ID</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ details.payment_methods.title }}</td>
                        <td v-if="details.txn_id">{{details.txn_id}}</td>
                        <td v-else class="text-center">- -</td>
                        <td>{{ details.paid_amount }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- Shipping-detail -->
                <div class="footer_detail mt-2 mb-0 p-2" v-if="details.shipping_details">
                  <label class="content-label mb-1">Shipping Detail</label>
                  <p class="mg-b-0">
                    Vehicle No : {{ details.shipping_details.vehicle }}
                  </p>
                  <p class="mg-b-0">
                    Driver Name : {{ details.shipping_details.driver }}
                  </p>
                  <p class="mg-b-0">
                    Contact No : {{ details.shipping_details.contact }}
                  </p>
                </div>
                <!-- Shipping-detail -->
              </div>
              <!-- col -->
              <div
                class="col-sm-4 col-lg-4 order-1 order-sm-0 color-secondary mb-0 mt-0"
              >
                <ul class="list-unstyled lh-6 mb-0">
                  <li class="d-flex justify-content-between">
                    <span>Sub-Total</span>
                    <span>{{ details.total }}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>VAT able Amount</span>
                    <span>{{ details.vatable_amount }}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>VAT (13%)</span>
                    <span>{{ details.vat_amount }}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>Total</span>
                    <span>{{ details.bill_amount }}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>Total Paid</span>
                    <span>{{ details.paid_amount }}</span>
                  </li>
                  <li class="d-flex justify-content-between">
                    <span>Total Due</span>
                    <span>{{ details.due_amount }}</span>
                  </li>
                </ul>
              </div>
              <!-- col -->
            </div>
            <hr />
            <div class="footer_note">
              <label class="content-label mb-1">Note</label>
              <p class="mb-2">
                {{ details.note }}
              </p>
            </div>
            <p class="text-right" v-if="dataLists9.purchase_setting"> <span v-if="dataLists9.purchase_setting.user_name==1">User: {{ details.user.name }}</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import VueHtmlToPaper from "vue-html-to-paper";
const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    'http://localhost:8080/assets/css/cassie.css',
    'http://localhost:8080/assets/css/bootstrap.css',
    'http://localhost:8080/assets/css/changes.css',
  ],
};

Vue.use(VueHtmlToPaper, options);
export default {
  data() {
    return {
      details: [],
    };
  },
  computed: {
    ...mapGetters(["dataLists","dataLists3","dataLists9", "dataId", "modalId", "modalMode"]),
    ...mapGetters("suppliers",["purchaseDatas"])
  },
  methods: {
    print() {
      this.$htmlToPaper("printMe");
    },
  },
  watch: {
    modalId() {
      if(this.modalId == 'purchaseSlip'){
        let id  = this.dataId;
        this.details = this.purchaseDatas.find(function (data) {
        return data.id == id;
      });
      }
      
    },
  },
};
</script>