<template>
  <div
    class="tab-pane fade"
    id="payins"
    role="tabpanel"
    aria-labelledby="contact-tab5"
  >
    <div class="bg-theam-secondary table-search-head pt-0">
      <div class="row">
        <div class="filter-input col-md-9 d-block mr-0 pr-0">
          <select v-model="payInParams.size" @change="filterPayIn" class="mr-2 mg-t-5">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                  <div class="mr-2 d-inline-block">
                    <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
                    <input
                      type="date"
                      v-model="payInParams.from"
                      placeholder="From"
                      class="filter-input mr-1 mg-t-5"
                       @change="filterPayIn"
                    />
                    <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
                    <input
                      type="date"
                      v-model="payInParams.to"
                      placeholder="To"
                      class="filter-input mr-1 mg-t-5"
                         @change="filterPayIn"
                    />
          </div>
        </div>
        <div class="col-md-3 mg-t-5">
          <div class="text-right">
            <button class="btn-icons">
              <i class="fas fa-print pr-1"></i>
            </button>
            <button class="btn-icons">
              <i class="fas fa-file-pdf pr-1"></i>
            </button>
            <button class="btn-icons">
              <i class="fas fa-file-excel pr-1"></i>
            </button>
            <button class="btn-icons">
              <i class="fas fa-download"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-x-auto">
      <table class="table table2 table-hover">
        <thead>
          <tr>
            <th class="wd-5px table-start-item">S.N.</th>
            <th class="wd-20p">Dates</th>
            <th class="wd-20p">Receipt ID</th>
            <th class="wd-25p">Payment Mode</th>
            <th class="wd-20p">Paid Amount</th>
            <th class="wd-10px text-right table-end-item no-print">Action</th>
          </tr>
        </thead>
        <tbody v-if="!payInParams.loading && payInDatas.length > 0">
          <tr v-for="(receipts, index) in payInDatas" :key="index">
            <td class="table-start-item">{{ payInParams.pageSerialNo+index }}.</td>
            <td>{{ receipts.date }}</td>
            <td>{{ receipts.receipt_no }}</td>
            <td>{{ receipts.payment_methods.title }}</td>
            <td>{{ receipts.paid_amount }}</td>
            <td class="text-right table-end-item no-print">
              <a href="javascript:;" class="mr-3" title="View Slip">
                <i
                  class="fas fa-eye"
                  @click="view('payInSlip', receipts.id)"
                ></i>
              </a>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="!payInParams.loading && payInDatas.length == 0">
            <tr>
              <td  colspan="11" style="text-align:center;">
                No records found.
              </td>
            </tr>
        </tbody>
        <tbody v-else-if="payInParams.loading">
            <tr>
              <td  colspan="11" style="text-align:center;">
                Loading.....
              </td>
            </tr>
        </tbody>
        <tbody v-else-if="payInParams.error">
            <tr>
            <td colspan="11" class="text-center">
              {{payInParams.error_message}}
            </td>
          </tr>
        </tbody>
    </table>
    </div>
    <ReceiptSlip />
    <Paginate :pageCount="payInParams.totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="payInParams.totalPageCount > 1"
          />
  </div>

  <!-- content -->
</template>
<script>
import { mapGetters } from "vuex";
import Paginate from 'vuejs-paginate';
import ReceiptSlip from "../Supplier/PaymentSlip";
import Services from "./Services/Services"
export default {
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists1",
      "dataLists2",
      "dataLists3",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("suppliers",["payInDatas","payInParams"])
  },
  components: {
    ReceiptSlip,
    Paginate
  },
  data(){
    return{
       pageSerialNo:1,
    }
  },
  methods: {
    clickCallback: function(page) {
      if(page == 1){
        this.payInParams.pageSerialNo = 1;
      }else{
        this.payInParams.pageSerialNo = ((page-1) * this.payInParams.size) + 1 ;
      }
      this.payInParams.offset = (page-1) * this.payInParams.size;
      this.filterPayIn();
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    edit(modal,id){
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "editPayout" });
    },
    filterPayIn(){
      this.payInParams.loading=true;
      Services.filterPayInDatas(this.payInParams).then(res=>{
         this.$store.commit("suppliers/setPayInDatas",res.data.data.data);
         this.payInParams.totalPageCount = res.data.data.total_no_of_pages;
         this.payInParams.loading =false;
      }).catch(err=>{
          this.payInParams.error = true;
          let error = err.response.data.error_message;
           this.setNotification(error);
           if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.payInParams.error_message = "Access Denied !!!";
            } else {
              this.payInParams.error_message = "Error fetching data from the server.";
            }
          }
      }).finally(()=>{
          this.payInParams.loading = false;
      });
    },
    setNotification(message){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",message);
    },
  },
};
</script>
<style scoped>
</style>