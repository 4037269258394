<template>
  <div class v-if="supplierDatas">
    <!-- Supplier Detail for Print Out -->
    <div class="row print-detail-only mx-3 mt-3 mb-0">
      <div class="col-sm-4 col-lg-4 mg-t-10 mg-sm-t-0 order-2 order-sm-0">
        <label class="content-label mb-1">Supplier</label>
        <p class="mg-b-0">NAME : {{ supplierDatas.name }}</p>
        <p class="mg-b-0">ADDRESS : {{ supplierDatas.address }}</p>
        <p class="mg-b-0">PAN : {{ supplierDatas.pan_vat_no }}</p>
      </div>
      <!-- col -->
      <div class="col-sm-4 col-lg-4 mg-t-30">
        <p class="text-center font-l m-0">Supplier Statement</p>
        <hr class="print-sm-hr print-hr-margin" />
      </div>
      <div class="col-sm-4 col-lg-4 mg-t-30 order-1 order-sm-0">
        <p class="mg-b-0 text-sm-right">DATE : current Date</p>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="content-body content-body-profile mt-3 mt-xl-0">
      <div class="profile-sidebar">
        <div class="content-header pd-t-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/supplier">Supplier</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Supplier Detail</li>
              </ol>
            </nav>
          </div>
        </div>
        <!-- content-header -->
        <div class="profile-sidebar-header">
          <div class="avatar">
            <img
              :src="supplierDatas.image?supplierDatas.image:'/assets/img/logosmall.png'"
              class="rounded-circle"
              alt
            />
          </div>

          <h5>{{ supplierDatas.name }}</h5>
          <div class="d-flex">
            <!-- <h6>{{ supplierDatas.supplier_id }}</h6> -->
            <!-- <div class="tx-primary pd-l-5 tx-medium">Active</div> -->
          </div>
          <div class="d-flex align-self-stretch mt-1">
            <a href class="btn btn-sm btn-primary btn-uppercase flex-fill">Email</a>
            <button
              v-if="checkSinglePermission('edit_supplier')"
              class="btn btn-white btn-sm btn-uppercase flex-fill mg-l-5"
              @click.prevent="edit('editSupplierProfile',supplierDatas.id)"
            >Edit</button>
          </div>
        </div>

        <div class="profile-sidebar-body">
          <label class="content-label">Contact Details</label>
          <ul class="list-unstyled profile-info-list mg-b-0">
            <li>
              <i data-feather="briefcase"></i>
              <span class="tx-color-03">{{ supplierDatas.address }}</span>
            </li>
            <li>
              <i data-feather="phone"></i>
              <span>{{ supplierDatas.phone }}</span>
            </li>
            <li>
              <i data-feather="mail"></i>
              <span>{{ supplierDatas.email }}</span>
            </li>
            <li>
              <i data-feather="mail"></i>
              <a :href="supplierDatas.website" target="_blank">{{ supplierDatas.website }}</a>
            </li>
          </ul>
          <hr class="mg-y-10" />

          <label class="content-label">Other Detail</label>
          <ul class="list-unstyled profile-info-list">
            <li>
              <span class="tx-color-03">PAN / VAT: {{ supplierDatas.pan_vat_no }}</span>
            </li>
            <li>
              <span class="tx-color-03">Supplier Created: {{ supplierDatas.member_since }}</span>
            </li>
            <li>
              <span class="tx-color-03">
                Created By:
                <span v-if="supplierDatas.user">{{supplierDatas.user_details.name}}</span>
              </span>
            </li>
            <li>
              <span class="tx-color-03">
                Opening Balance:
                {{ parseDigitForSlip(supplierDatas.opening_due) }}
              </span>
            </li>
            <li>
              <span class="tx-color-03">
                Closing Balance:
                {{ parseDigitForSlip(supplierDatas.closing_due) }}
              </span>
            </li>
          </ul>
        </div>
        <!-- profile-sidebar-body -->
      </div>
      <!-- profile-sidebar -->
      <div class="profile-body">
        <div class="profile-body-header">
          <div class="nav-wrapper">
            <ul class="nav nav-line" id="profileTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="overview-tab"
                  data-toggle="tab"
                  href="#overview"
                  role="tab"
                  aria-controls="overview"
                  aria-selected="true"
                >Overview</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="projects-tab"
                  data-toggle="tab"
                  href="#bills"
                  role="tab"
                  aria-controls="projects"
                  aria-selected="false"
                  @click="purchase()"
                >Purchases</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="people-tab"
                  data-toggle="tab"
                  href="#payins"
                  role="tab"
                  aria-controls="people"
                  aria-selected="false"
                  @click="payin()"
                >Receipt Voucehers</a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="people-tab"
                  data-toggle="tab"
                  href="#receipts"
                  role="tab"
                  aria-controls="people"
                  aria-selected="false"
                  @click="receipt()"
                >Payment Vouchers</a>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  id="ledger-tab"
                  data-toggle="tab"
                  href="#ledger"
                  role="tab"
                  aria-controls="ledger"
                  aria-selected="false"
                  @click="ledger()"
                >Ledger</a>
              </li>
            </ul>
          </div>
          <!-- nav-wrapper -->
        </div>
        <!-- profile-body-header -->
        <!-- Purchase panel Start -->
        <div class="tab-content p-3">
          <div id="overview" class="tab-pane active show">
            <div class="stat-profile">
              <div class="stat-profile-body">
                <div class="row row-xs px-2">
                  <div class="col pd-r-7-5 pd-l-7-5">
                    <div class="card card-body pd-10 pd-md-15 bd-0 shadow-none bg-primary-light">
                      <h3
                        class="tx-normal tx-sans tx-spacing--4 tx-primary mg-b-5"
                      >{{ supplierDatas.closing_due }}</h3>
                      <p class="font-s tx-color-02 mg-b-0">Due Amount</p>
                    </div>
                  </div>
                  <div class="col pd-r-7-5 pd-l-7-5">
                    <div class="card card-body pd-10 pd-md-15 bd-0 shadow-none bg-teal-light">
                      <h3 class="mg-b-5 tx-sans tx-spacing--2 tx-normal tx-teal">
                        <span v-if="dataLists2.purchases">{{ dataLists2.purchases.total_bills }}</span>
                      </h3>
                      <p class="font-s tx-color-03 mg-b-0">Total Purchases</p>
                    </div>
                  </div>
                  <div class="col pd-r-7-5 pd-l-7-5">
                    <div class="card card-body pd-10 pd-md-15 bd-0 shadow-none bg-pink-light">
                      <h3 class="mg-b-5 tx-sans tx-spacing--2 tx-normal tx-pink">
                        <span v-if="dataLists2.purchases">{{ dataLists2.purchases.count }}</span>
                      </h3>
                      <p class="font-s tx-color-03 mg-b-0">Total Purchase Bills</p>
                    </div>
                  </div>
                </div>
                <!-- row -->
              </div>
              <!-- stat-profile-body -->
            </div>
            <!-- stat-profile -->

            <label class="content-label content-label-lg mt-3 tx-color-01">Description</label>
            <p class="tx-color-03 m-0">{{ supplierDatas.description }}</p>

            <hr class="mt-3" />
            <div class="row no-print">
              <div class="col-6">
                <label class="content-label content-label-lg pt-1 tx-color-01 mb-0">Contact Persons</label>
              </div>
              <div class="col-6 tx-right">
                <button
                  v-if="checkSinglePermission('create_contact_detail')"
                  class="btn btn-xs btn-primary"
                  @click="$store.dispatch('modalOpen', 'newContact')"
                >Add Contact Person</button>
              </div>
            </div>
            <div class="row pd-r-7-5 pd-l-7-5" v-if="checkSinglePermission('view_contact_detail')">
              <div
                class="col-md-4 mt-3 pd-r-7-5 pd-l-7-5"
                v-for="(contactDetails, index) in dataLists2.contact_details"
                :key="index"
              >
                <div class="card p-3">
                  <div class="contact-action-container">
                    <div
                      class="contant-action-icon mr-2"
                      v-if="checkSinglePermission('edit_contact_detail')"
                      title="Edit"
                      @click="editContactDetails('editContact',contactDetails.id)"
                    >
                      <i class="fa fa-edit tx-primary"></i>
                    </div>
                    <div
                      class="contant-action-icon"
                      v-if="checkSinglePermission('delete_contact_detail')"
                      title="Delete"
                      @click="dropContactDetails(contactDetails.id,index)"
                    >
                      <i class="fa fa-trash tx-danger"></i>
                    </div>
                  </div>
                  <ul class="list-unstyled profile-info-list mb-0">
                    <li>
                      <span class="tx-color-03">Name: {{ contactDetails.name }}</span>
                    </li>
                    <li>
                      <span class="tx-color-03">Job Title: {{ contactDetails.designation }}</span>
                    </li>
                    <li>
                      <span class="tx-color-03">Phone: {{ contactDetails.phone_no }}</span>
                    </li>
                    <li>
                      <span class="tx-color-03">Email:{{ contactDetails.email }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="bills" role="tabpanel" aria-labelledby="contact-tab5">
            <div class="bg-theam-secondary table-search-head pt-0">
              <div class="row">
                <div class="filter-input col-md-9 d-block mr-0 pr-0">
                  <select v-model="params.size" @change="filterPurchase" class="mr-2 mg-t-5">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                  </select>
                  <div class="d-inline-block">
                    <label class="filter-lavel mb-0 mr-1 mg-t-5">From</label>
                    <input
                      type="date"
                      v-model="params.from"
                      placeholder="From"
                      class="filter-input mr-1 mg-t-5"
                      @change="filterPurchase"
                    />
                    <label class="filter-lavel mb-0 mr-1 mg-t-5">To</label>
                    <input
                      type="date"
                      v-model="params.to"
                      placeholder="To"
                      class="filter-input mr-1 mg-t-5"
                      @change="filterPurchase"
                    />
                  </div>
                </div>
                <div class="col-md-3 mg-t-5">
                  <div class="text-right">
                    <button class="btn-icons">
                      <i class="fas fa-print pr-1"></i>
                    </button>
                    <button class="btn-icons">
                      <i class="fas fa-file-pdf pr-1"></i>
                    </button>
                    <button class="btn-icons">
                      <i class="fas fa-file-excel pr-1"></i>
                    </button>
                    <button class="btn-icons">
                      <i class="fas fa-download"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="overflow-x-auto">
              <table class="table table2 table-hover">
                <thead>
                  <tr>
                    <th class="wd-5px table-start-item">S.N.</th>
                    <th class="wd-10p">Date</th>
                    <th class="wd-10p">PurchaseID</th>
                    <th class="wd-10p">Purchase Amount</th>
                    <th class="wd-10p">Vat</th>
                    <th class="wd-10p">Bill Amt.</th>
                    <th class="wd-10p">Paid Amt.</th>
                    <th class="wd-15p">Payment mode</th>
                    <th class="wd-10p">Due</th>
                    <th
                      class="wd-10p text-right table-end-item no-print"
                      v-if="checkSinglePermission('view_purchase')"
                    >Action</th>
                  </tr>
                </thead>

                <tbody v-if="!loading && purchaseDatas.length > 0">
                  <tr v-for="(purchases, index) in purchaseDatas" :key="index">
                    <td class="table-start-item">{{ pageSerialNo+index }}.</td>
                    <td>{{ purchases.date }}</td>
                    <td>{{purchases.prefix}}{{ purchases.purchase_no }}</td>
                    <td>{{ parseDigitForSlip(purchases.total) }}</td>
                    <td>{{ parseDigitForSlip(purchases.vat_amount) }}</td>
                    <td>{{ parseDigitForSlip(purchases.bill_amount) }}</td>
                    <td>{{ parseDigitForSlip(purchases.paid_amount) }}</td>
                    <td>{{ purchases.payment_methods.title }}</td>
                    <td>{{ parseDigitForSlip(purchases.due_amount) }}</td>
                    <td
                      class="text-right table-end-item no-print"
                      v-if="checkSinglePermission('view_purchase')"
                    >
                      <a href="javascript:;" class="mr-3" title="View Slip">
                        <i class="fas fa-eye" @click="view('purchaseSlip', purchases.id)"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="!loading && purchaseDatas.length == 0">
                  <tr>
                    <td colspan="11" style="text-align:center;">No records found.</td>
                  </tr>
                </tbody>
                <tbody v-else-if="loading">
                  <tr>
                    <td colspan="11" style="text-align:center;">Loading.....</td>
                  </tr>
                </tbody>
                <tbody v-else-if="error">
                  <tr>
                    <td colspan="11" style="text-align:center;">{{error_message}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <Paginate
              :pageCount="totalPageCount"
              :containerClass="'pagination'"
              :clickHandler="clickCallback"
              v-if="totalPageCount > 1"
            />
          </div>
          <!---individual content-->
          <ProfileReceipt />
          <!--individual-content-->
          <ProfileLedger />
          <!--individual content -->
          <ProfilePayIn />
        </div>
        <!-- Purchase Panel End -->
        <!-- tab-content -->
      </div>
      <!-- profile-body -->
    </div>
    <!-- content-body -->
    <ContactDetail />
    <PurchaseSlip />
    <EditSupplier />
  </div>
  <!-- content -->
</template>
<script>
import { Search } from "../../../../mixins/search";
import { mapGetters } from "vuex";
import Paginate from "vuejs-paginate";
import ContactDetail from "../Supplier/AddContactPerson";
import EditSupplier from "./EditSupplier";
import ProfileReceipt from "../Supplier/ProfileReceipt";
import ProfilePayIn from "../Supplier/PayIn";
import ProfileLedger from "../Supplier/ProfileLedger";
import PurchaseSlip from "../Supplier/PurchaseSlip";
import Services from "./Services/Services";
export default {
  mixins: [Search],
  data() {
    return {
      params: {
        size: "10",
        from: "",
        to: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        id: this.$route.params.id,
        offset: 0,
      },
      loading: true,
      error: false,
      error_message: "",
      totalPageCount: 0,
      pageSerialNo: 1,
    };
  },
  components: {
    ContactDetail,
    ProfileReceipt,
    ProfileLedger,
    PurchaseSlip,
    EditSupplier,
    Paginate,
    ProfilePayIn,
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists2",
      "dataLists3",
      "dataLists9",
      "eventMessage",
      "modalId",
    ]),
    ...mapGetters("suppliers", [
      "ledgerDatas",
      "receiptDatas",
      "payInDatas",
      "supplierDatas",
      "purchaseDatas",
      "receiptParams",
      "payInParams",
      "ledgerParams",
    ]),
  },
  methods: {
    clickCallback: function (page) {
      if (page == 1) {
        this.pageSerialNo = 1;
      } else {
        this.pageSerialNo = (page - 1) * this.params.size + 1;
      }
      this.params.offset = (page - 1) * this.params.size;
      this.sale();
    },
    view(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "read" });
    },
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    editContactDetails(modal, id) {
      console.log(id);
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalWithMode", { id: modal, mode: "edit" });
    },
    dropContactDetails(id, index) {
      this.$swal({
        title: "Do you really want to delete ContactDetail ?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return Services.deleteContactDetails(id)
            .then((res) => {
              if (
                res.data.success_message ==
                "Contact Person deleted successfully."
              ) {
                this.$delete(this.dataLists2.contact_details, index);
                this.setNotification(res.data.success_message);
              }
            })
            .catch((err) => {
              this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              let error = err.response.error_message;
              this.setNotification(error);
            });
        },
      });
    },
    drop(id) {
      this.$store.commit("setDataId", id);
      this.$store.commit("dropRecord", `api/purchase/${id}`);
    },
    ledger() {
      let ledger_params = {
        size: "10",
        from: "",
        to: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        id: this.$route.params.id,
        loading: true,
        error: false,
        error_message:'',
      };
      this.$store.commit("suppliers/setLedgerParams", ledger_params);
      Services.getLedgerDataForSupplier(this.$route.params.id)
        .then((res) => {
          this.$store.commit("suppliers/setLedgerDatas", res.data.data);
          this.ledgerParams.loading = false;
        })
        .catch((err) => {
          this.ledgerParams.error = true;
          this.ledgerParams.loading= false;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.ledgerParams.error_message = "Access Denied !!!";
            } else {
              this.ledgerParams.error_message = "Error fetching data from the server.";
            }
          }
        });
    },
    receipt() {
      let receipt_params = {
        size: "10",
        from: "",
        to: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        id: this.$route.params.id,
        loading: true,
        error: false,
        error_message:'',
        offset: 0,
        totalPageCount: 0,
      };
      this.$store.commit("suppliers/setReceiptParams", receipt_params);
      Services.filterReceiptDatas(this.params)
        .then((res) => {
          this.$store.commit("suppliers/setReceiptDatas", res.data.data.data);
          this.receiptParams.totalPageCount = res.data.data.total_no_of_pages;
          this.receiptParams.loading = false;
        })
        .catch((err) => {
          this.receiptParams.error = true;
          this.receiptParams.loading= false;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.receiptParams.error_message = "Access Denied !!!";
            } else {
              this.receiptParams.error_message = "Error fetching data from the server.";
            }
          }
        });
    },
    payin() {
      let payin_params = {
        size: "10",
        from: "",
        to: new Date().toJSON().slice(0, 10).replace(/-/g, "-"),
        id: this.$route.params.id,
        loading: true,
        error: false,
        error_message:'',
        offset: 0,
        totalPageCount: 0,
        pageSerialNo: 1,
      };
      this.$store.commit("suppliers/setPayInParams", payin_params);
      Services.filterPayInDatas(this.params)
        .then((res) => {
          this.$store.commit("suppliers/setPayInDatas", res.data.data.data);
          this.payInParams.totalPageCount = res.data.data.total_no_of_pages;
          this.payInParams.loading = false;
        })
        .catch((err) => {
          this.payInParams.error = true;
          this.payInParams.loading = false;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.payInParams.error_message = "Access Denied !!!";
            } else {
              this.payInParams.error_message = "Error fetching data from the server.";
            }
          }
        });
    },
    purchase() {
      this.loading = true;
      Services.filterPurchaseDatas(this.params)
        .then((res) => {
          this.$store.commit("suppliers/setPurchaseDatas", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
        })
        .catch((err) => {
          this.error = true;
          this.loading=false;
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
            } else {
              this.error_message = "Error fetching data from the server.";
            }
          }
              let error = err.response.data.error_message;
              this.setNotification(error);
        });
    },
    getData() {
      this.loading = true;
      this.$store.commit("suppliers/setSupplierDatas", []);
      Services.getSupplierProfileData(this.$route.params.id)
        .then((res) => {
          this.$store.commit("setDataLists2", res.data.data);
          //this.$store.commit("getData9", `api/account/pay-in/create/elements`);
          this.$store.commit(
            "suppliers/setSupplierDatas",
            res.data.data.supplier
          );
          this.loading = false;
        })
        .catch((err) => {
          this.loading=false;
          this.error = true;
          console.log(err);
        });
    },
    filterPurchase() {
      this.loading = true;
      Services.filterPurchaseDatas(this.params)
        .then((res) => {
          this.$store.commit("setDataLists3", res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading = false;
          this.setNotification(res.data.success_message);
        })
        .catch((err) => {
          this.error = true;
          this.loading=false;
          let error = err.response.data.error_message;
          this.setNotification(error);
          if (
            !Object.prototype.hasOwnProperty.call(
              err,
              "response.data.error_message"
            )
          ) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
            } else {
              this.error_message = "Error fetching data from the server.";
            }
          }
        });
    },
    setNotification(message) {
      this.$store.dispatch("enableMessage");
      this.$store.commit("setEventMessage", message);
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("created successfully") >= 0) {
        this.getData();
      }
    },
  },
};
</script>
<style scoped>
</style>