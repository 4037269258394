<template>
    <div class="col-md-12">
        <div class="modal fade modal_cust show" v-if="modalId=='newContact'||modalId=='editContact'" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" style="display: block;" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal_ac_head single-column-popup" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalCenterTitle">Add Contact Person</h5>
                        <a class="close"  @click="$store.dispatch('modalClose')">
                            <span aria-hidden="true">×</span>
                        </a>
                    </div>
                    <form @submit.prevent="addContactPerson" class="bg-theam-secondary">
                        <div class="modal-body row">
                            <div class="col-md-12 pd-r-7-5">
                                <div class="group">
                                    <label class="group-label">Detail</label>
                                    <div class="group-attribute-container">
                                        <div class="row mb-2">
                                            <div class="col-md-5 mr-0 pr-0">Name *</div>
                                            <div class="col-md-7 ml-0 pl-0">
                                                <input type="text" v-model="formData.name" placeholder="Name" class="form-control" required>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-5 mr-0 pr-0">Designation</div>
                                            <div class="col-md-7 ml-0 pl-0">
                                                <input type="text" v-model="formData.designation" placeholder="CEO" class="form-control" >
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-5 mr-0 pr-0">Phone No *</div>
                                            <div class="col-md-7 ml-0 pl-0">
                                                <input type="tel" v-model="formData.phone_no" placeholder="98xxxxxxx" class="form-control" maxlength="25" required>

                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-5 mr-0 pr-0">Email </div>
                                            <div class="col-md-7 ml-0 pl-0">
                                                <input type="email" v-model="formData.email" placeholder="abc@domain.com" class="form-control">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 text-right mt-3">
                                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">{{ disableSubmitButton ? 'Submitting....' : 'Submit'}}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
export default {
    data(){
        return{
            formData:{
              'designation':'',
              'name':'',
              'phone_no':'',
              'email':''
            },
            disableSubmitButton:false,
        }
    },
  computed: {
      ...mapGetters(["eventMessage","dataLists","dataLists2","dataId","modalId","modalState"]),
  },
  mounted() {
      this.formData.company=this.$route.params.id;
      this.formData.catagory = this.dataLists2.account_type;
  },
  methods:{
       addContactPerson(){
        this.disableSubmitButton =true;
        if(this.modalId == 'newContact'){
            Services.createContactPerson(this.formData).then(res=>{
                this.$store.dispatch("modalClose");
                this.setNotification(res.data.success_message);
                this.disableSubmitButton = false;
            }).catch(err=>{
                this.disableSubmitButton = false;
                console.log(err);
                if(err.response.status == 422){
                    let error = Object.values(err.response.data.errors).flat();
                    this.setNotification(error);
                }else{
                    let error = err.response.data.error_message;
                    this.setNotification(error);
                }
            });
        }
        if(this.modalId == 'editContact'){
            Services.updateContactPerson(this.formData,this.formData.id).then(response=>{
                Services.getSupplierProfileData(this.$route.params.id).then(res=>{
                    this.$store.commit("suppliers/setSupplierDatas",res.data.data.supplier);
                    this.$store.commit("setDataLists2",res.data.data);
                    this.$store.dispatch("modalClose");
                    this.setNotification(response.data.success_message);
                    this.disableSubmitButton = false;
                }).catch(err=>{
                    this.error = true;
                    console.log(err);
                })
            }).catch(err=>{
                console.log(err);
                this.disableSubmitButton =false;
                if(err.response.status == 422){
                    let error = Object.values(err.response.data.errors).flat();
                    this.setNotification(error);
                }else{
                    console.log(err);
                }
            });
        }
        },
        setNotification(message){
            this.$store.dispatch("enableMessage");
            this.$store.commit("setEventMessage",message);
    },
    },
    watch:{
        // eventMessage(value){
        //     if(value.indexOf("success") >= 0){
        //         this.$store.dispatch('modalClose');

        //     }
        // },
        modalId(){
            if(this.modalId == 'newContact'){
                this.formData.company=this.$route.params.id;
                this.formData.catagory = this.dataLists2.account_type;
                this.formData.designation = '';
                this.formData.name = '';
                this.formData.phone_no ='';
                this.formData.email = '';
            }
            if(this.modalId == 'editContact'){
                let id = this.dataId;
                let details = this.dataLists2.contact_details.find(function (data) {
                    return data.id == id;
                });
                this.formData = {...details};
            }
        }
    }
}
</script>